"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Osmosis Unchained API
 * Provides access to osmosis chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatorCommissionToJSON = exports.ValidatorCommissionFromJSONTyped = exports.ValidatorCommissionFromJSON = exports.instanceOfValidatorCommission = void 0;
/**
 * Check if a given object implements the ValidatorCommission interface.
 */
function instanceOfValidatorCommission(value) {
    let isInstance = true;
    isInstance = isInstance && "maxChangeRate" in value;
    isInstance = isInstance && "maxRate" in value;
    isInstance = isInstance && "rate" in value;
    return isInstance;
}
exports.instanceOfValidatorCommission = instanceOfValidatorCommission;
function ValidatorCommissionFromJSON(json) {
    return ValidatorCommissionFromJSONTyped(json, false);
}
exports.ValidatorCommissionFromJSON = ValidatorCommissionFromJSON;
function ValidatorCommissionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'maxChangeRate': json['maxChangeRate'],
        'maxRate': json['maxRate'],
        'rate': json['rate'],
    };
}
exports.ValidatorCommissionFromJSONTyped = ValidatorCommissionFromJSONTyped;
function ValidatorCommissionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'maxChangeRate': value.maxChangeRate,
        'maxRate': value.maxRate,
        'rate': value.rate,
    };
}
exports.ValidatorCommissionToJSON = ValidatorCommissionToJSON;
