"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/optimism-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptimismGasFeesToJSON = exports.OptimismGasFeesFromJSONTyped = exports.OptimismGasFeesFromJSON = exports.instanceOfOptimismGasFees = void 0;
const runtime_1 = require("../runtime");
const Fees_1 = require("./Fees");
/**
 * Check if a given object implements the OptimismGasFees interface.
 */
function instanceOfOptimismGasFees(value) {
    let isInstance = true;
    isInstance = isInstance && "gasPrice" in value;
    isInstance = isInstance && "slow" in value;
    isInstance = isInstance && "average" in value;
    isInstance = isInstance && "fast" in value;
    isInstance = isInstance && "l1GasPrice" in value;
    return isInstance;
}
exports.instanceOfOptimismGasFees = instanceOfOptimismGasFees;
function OptimismGasFeesFromJSON(json) {
    return OptimismGasFeesFromJSONTyped(json, false);
}
exports.OptimismGasFeesFromJSON = OptimismGasFeesFromJSON;
function OptimismGasFeesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gasPrice': json['gasPrice'],
        'baseFeePerGas': !(0, runtime_1.exists)(json, 'baseFeePerGas') ? undefined : json['baseFeePerGas'],
        'maxPriorityFeePerGas': !(0, runtime_1.exists)(json, 'maxPriorityFeePerGas') ? undefined : json['maxPriorityFeePerGas'],
        'slow': (0, Fees_1.FeesFromJSON)(json['slow']),
        'average': (0, Fees_1.FeesFromJSON)(json['average']),
        'fast': (0, Fees_1.FeesFromJSON)(json['fast']),
        'l1GasPrice': json['l1GasPrice'],
    };
}
exports.OptimismGasFeesFromJSONTyped = OptimismGasFeesFromJSONTyped;
function OptimismGasFeesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gasPrice': value.gasPrice,
        'baseFeePerGas': value.baseFeePerGas,
        'maxPriorityFeePerGas': value.maxPriorityFeePerGas,
        'slow': (0, Fees_1.FeesToJSON)(value.slow),
        'average': (0, Fees_1.FeesToJSON)(value.average),
        'fast': (0, Fees_1.FeesToJSON)(value.fast),
        'l1GasPrice': value.l1GasPrice,
    };
}
exports.OptimismGasFeesToJSON = OptimismGasFeesToJSON;
