"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/gnosis-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountToJSON = exports.AccountFromJSONTyped = exports.AccountFromJSON = exports.instanceOfAccount = void 0;
const TokenBalance_1 = require("./TokenBalance");
/**
 * Check if a given object implements the Account interface.
 */
function instanceOfAccount(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "unconfirmedBalance" in value;
    isInstance = isInstance && "pubkey" in value;
    isInstance = isInstance && "nonce" in value;
    isInstance = isInstance && "tokens" in value;
    return isInstance;
}
exports.instanceOfAccount = instanceOfAccount;
function AccountFromJSON(json) {
    return AccountFromJSONTyped(json, false);
}
exports.AccountFromJSON = AccountFromJSON;
function AccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': json['balance'],
        'unconfirmedBalance': json['unconfirmedBalance'],
        'pubkey': json['pubkey'],
        'nonce': json['nonce'],
        'tokens': (json['tokens'].map(TokenBalance_1.TokenBalanceFromJSON)),
    };
}
exports.AccountFromJSONTyped = AccountFromJSONTyped;
function AccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': value.balance,
        'unconfirmedBalance': value.unconfirmedBalance,
        'pubkey': value.pubkey,
        'nonce': value.nonce,
        'tokens': (value.tokens.map(TokenBalance_1.TokenBalanceToJSON)),
    };
}
exports.AccountToJSON = AccountToJSON;
