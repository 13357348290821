"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Osmosis Unchained API
 * Provides access to osmosis chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTxToJSON = exports.BaseTxFromJSONTyped = exports.BaseTxFromJSON = exports.instanceOfBaseTx = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the BaseTx interface.
 */
function instanceOfBaseTx(value) {
    let isInstance = true;
    isInstance = isInstance && "blockHeight" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "txid" in value;
    return isInstance;
}
exports.instanceOfBaseTx = instanceOfBaseTx;
function BaseTxFromJSON(json) {
    return BaseTxFromJSONTyped(json, false);
}
exports.BaseTxFromJSON = BaseTxFromJSON;
function BaseTxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blockHash': !(0, runtime_1.exists)(json, 'blockHash') ? undefined : json['blockHash'],
        'blockHeight': json['blockHeight'],
        'timestamp': json['timestamp'],
        'txid': json['txid'],
    };
}
exports.BaseTxFromJSONTyped = BaseTxFromJSONTyped;
function BaseTxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blockHash': value.blockHash,
        'blockHeight': value.blockHeight,
        'timestamp': value.timestamp,
        'txid': value.txid,
    };
}
exports.BaseTxToJSON = BaseTxToJSON;
