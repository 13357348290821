"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bitcoin-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkFeeToJSON = exports.NetworkFeeFromJSONTyped = exports.NetworkFeeFromJSON = exports.instanceOfNetworkFee = void 0;
/**
 * Check if a given object implements the NetworkFee interface.
 */
function instanceOfNetworkFee(value) {
    let isInstance = true;
    isInstance = isInstance && "satsPerKiloByte" in value;
    isInstance = isInstance && "blocksUntilConfirmation" in value;
    return isInstance;
}
exports.instanceOfNetworkFee = instanceOfNetworkFee;
function NetworkFeeFromJSON(json) {
    return NetworkFeeFromJSONTyped(json, false);
}
exports.NetworkFeeFromJSON = NetworkFeeFromJSON;
function NetworkFeeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'satsPerKiloByte': json['satsPerKiloByte'],
        'blocksUntilConfirmation': json['blocksUntilConfirmation'],
    };
}
exports.NetworkFeeFromJSONTyped = NetworkFeeFromJSONTyped;
function NetworkFeeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'satsPerKiloByte': value.satsPerKiloByte,
        'blocksUntilConfirmation': value.blocksUntilConfirmation,
    };
}
exports.NetworkFeeToJSON = NetworkFeeToJSON;
