"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/litecoin-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxToJSON = exports.TxFromJSONTyped = exports.TxFromJSON = exports.instanceOfTx = void 0;
const runtime_1 = require("../runtime");
const Vin_1 = require("./Vin");
const Vout_1 = require("./Vout");
/**
 * Check if a given object implements the Tx interface.
 */
function instanceOfTx(value) {
    let isInstance = true;
    isInstance = isInstance && "txid" in value;
    isInstance = isInstance && "blockHeight" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "vin" in value;
    isInstance = isInstance && "vout" in value;
    isInstance = isInstance && "confirmations" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "hex" in value;
    return isInstance;
}
exports.instanceOfTx = instanceOfTx;
function TxFromJSON(json) {
    return TxFromJSONTyped(json, false);
}
exports.TxFromJSON = TxFromJSON;
function TxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'txid': json['txid'],
        'blockHash': !(0, runtime_1.exists)(json, 'blockHash') ? undefined : json['blockHash'],
        'blockHeight': json['blockHeight'],
        'timestamp': json['timestamp'],
        'vin': (json['vin'].map(Vin_1.VinFromJSON)),
        'vout': (json['vout'].map(Vout_1.VoutFromJSON)),
        'confirmations': json['confirmations'],
        'value': json['value'],
        'fee': json['fee'],
        'hex': json['hex'],
    };
}
exports.TxFromJSONTyped = TxFromJSONTyped;
function TxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'txid': value.txid,
        'blockHash': value.blockHash,
        'blockHeight': value.blockHeight,
        'timestamp': value.timestamp,
        'vin': (value.vin.map(Vin_1.VinToJSON)),
        'vout': (value.vout.map(Vout_1.VoutToJSON)),
        'confirmations': value.confirmations,
        'value': value.value,
        'fee': value.fee,
        'hex': value.hex,
    };
}
exports.TxToJSON = TxToJSON;
