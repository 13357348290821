"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/optimism-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptimismGasEstimateToJSON = exports.OptimismGasEstimateFromJSONTyped = exports.OptimismGasEstimateFromJSON = exports.instanceOfOptimismGasEstimate = void 0;
/**
 * Check if a given object implements the OptimismGasEstimate interface.
 */
function instanceOfOptimismGasEstimate(value) {
    let isInstance = true;
    isInstance = isInstance && "gasLimit" in value;
    isInstance = isInstance && "l1GasLimit" in value;
    return isInstance;
}
exports.instanceOfOptimismGasEstimate = instanceOfOptimismGasEstimate;
function OptimismGasEstimateFromJSON(json) {
    return OptimismGasEstimateFromJSONTyped(json, false);
}
exports.OptimismGasEstimateFromJSON = OptimismGasEstimateFromJSON;
function OptimismGasEstimateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gasLimit': json['gasLimit'],
        'l1GasLimit': json['l1GasLimit'],
    };
}
exports.OptimismGasEstimateFromJSONTyped = OptimismGasEstimateFromJSONTyped;
function OptimismGasEstimateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gasLimit': value.gasLimit,
        'l1GasLimit': value.l1GasLimit,
    };
}
exports.OptimismGasEstimateToJSON = OptimismGasEstimateToJSON;
