"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bitcoincash-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VinScriptSigToJSON = exports.VinScriptSigFromJSONTyped = exports.VinScriptSigFromJSON = exports.instanceOfVinScriptSig = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the VinScriptSig interface.
 */
function instanceOfVinScriptSig(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfVinScriptSig = instanceOfVinScriptSig;
function VinScriptSigFromJSON(json) {
    return VinScriptSigFromJSONTyped(json, false);
}
exports.VinScriptSigFromJSON = VinScriptSigFromJSON;
function VinScriptSigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hex': !(0, runtime_1.exists)(json, 'hex') ? undefined : json['hex'],
    };
}
exports.VinScriptSigFromJSONTyped = VinScriptSigFromJSONTyped;
function VinScriptSigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hex': value.hex,
    };
}
exports.VinScriptSigToJSON = VinScriptSigToJSON;
