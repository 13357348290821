"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.txInteractsWithContract = exports.getSigHash = void 0;
const getSigHash = (inputData) => {
    if (!inputData)
        return;
    const length = inputData.startsWith('0x') ? 10 : 8;
    return inputData.slice(0, length);
};
exports.getSigHash = getSigHash;
const txInteractsWithContract = (tx, contract) => {
    return tx.to === contract;
};
exports.txInteractsWithContract = txInteractsWithContract;
