"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryTimeframe = void 0;
var HistoryTimeframe;
(function (HistoryTimeframe) {
    HistoryTimeframe["HOUR"] = "1H";
    HistoryTimeframe["DAY"] = "24H";
    HistoryTimeframe["WEEK"] = "1W";
    HistoryTimeframe["MONTH"] = "1M";
    HistoryTimeframe["YEAR"] = "1Y";
    HistoryTimeframe["ALL"] = "All";
})(HistoryTimeframe = exports.HistoryTimeframe || (exports.HistoryTimeframe = {}));
