"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bitcoincash-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkFeesToJSON = exports.NetworkFeesFromJSONTyped = exports.NetworkFeesFromJSON = exports.instanceOfNetworkFees = void 0;
const runtime_1 = require("../runtime");
const NetworkFee_1 = require("./NetworkFee");
/**
 * Check if a given object implements the NetworkFees interface.
 */
function instanceOfNetworkFees(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfNetworkFees = instanceOfNetworkFees;
function NetworkFeesFromJSON(json) {
    return NetworkFeesFromJSONTyped(json, false);
}
exports.NetworkFeesFromJSON = NetworkFeesFromJSON;
function NetworkFeesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fast': !(0, runtime_1.exists)(json, 'fast') ? undefined : (0, NetworkFee_1.NetworkFeeFromJSON)(json['fast']),
        'average': !(0, runtime_1.exists)(json, 'average') ? undefined : (0, NetworkFee_1.NetworkFeeFromJSON)(json['average']),
        'slow': !(0, runtime_1.exists)(json, 'slow') ? undefined : (0, NetworkFee_1.NetworkFeeFromJSON)(json['slow']),
    };
}
exports.NetworkFeesFromJSONTyped = NetworkFeesFromJSONTyped;
function NetworkFeesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fast': (0, NetworkFee_1.NetworkFeeToJSON)(value.fast),
        'average': (0, NetworkFee_1.NetworkFeeToJSON)(value.average),
        'slow': (0, NetworkFee_1.NetworkFeeToJSON)(value.slow),
    };
}
exports.NetworkFeesToJSON = NetworkFeesToJSON;
