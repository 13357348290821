"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Osmosis Unchained API
 * Provides access to osmosis chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountToJSON = exports.AccountFromJSONTyped = exports.AccountFromJSON = exports.instanceOfAccount = void 0;
const Delegation_1 = require("./Delegation");
const Redelegation_1 = require("./Redelegation");
const Reward_1 = require("./Reward");
const Unbonding_1 = require("./Unbonding");
const Value_1 = require("./Value");
/**
 * Check if a given object implements the Account interface.
 */
function instanceOfAccount(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "pubkey" in value;
    isInstance = isInstance && "unconfirmedBalance" in value;
    isInstance = isInstance && "accountNumber" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "sequence" in value;
    isInstance = isInstance && "delegations" in value;
    isInstance = isInstance && "redelegations" in value;
    isInstance = isInstance && "rewards" in value;
    isInstance = isInstance && "unbondings" in value;
    return isInstance;
}
exports.instanceOfAccount = instanceOfAccount;
function AccountFromJSON(json) {
    return AccountFromJSONTyped(json, false);
}
exports.AccountFromJSON = AccountFromJSON;
function AccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': json['balance'],
        'pubkey': json['pubkey'],
        'unconfirmedBalance': json['unconfirmedBalance'],
        'accountNumber': json['accountNumber'],
        'assets': (json['assets'].map(Value_1.ValueFromJSON)),
        'sequence': json['sequence'],
        'delegations': (json['delegations'].map(Delegation_1.DelegationFromJSON)),
        'redelegations': (json['redelegations'].map(Redelegation_1.RedelegationFromJSON)),
        'rewards': (json['rewards'].map(Reward_1.RewardFromJSON)),
        'unbondings': (json['unbondings'].map(Unbonding_1.UnbondingFromJSON)),
    };
}
exports.AccountFromJSONTyped = AccountFromJSONTyped;
function AccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': value.balance,
        'pubkey': value.pubkey,
        'unconfirmedBalance': value.unconfirmedBalance,
        'accountNumber': value.accountNumber,
        'assets': (value.assets.map(Value_1.ValueToJSON)),
        'sequence': value.sequence,
        'delegations': (value.delegations.map(Delegation_1.DelegationToJSON)),
        'redelegations': (value.redelegations.map(Redelegation_1.RedelegationToJSON)),
        'rewards': (value.rewards.map(Reward_1.RewardToJSON)),
        'unbondings': (value.unbondings.map(Unbonding_1.UnbondingToJSON)),
    };
}
exports.AccountToJSON = AccountToJSON;
