"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertValidChainPartsPair = exports.assertIsAssetReference = exports.assertIsAssetNamespace = exports.assertIsChainReference = exports.assertIsChainNamespace = exports.assertIsChainId = exports.isChainId = exports.isAssetId = exports.isAssetReference = exports.isAssetNamespace = exports.isChainReference = exports.isChainNamespace = void 0;
const constants_1 = require("./constants");
const utils_1 = require("./utils");
const isChainNamespace = (maybeChainNamespace) => Object.values(constants_1.CHAIN_NAMESPACE).some(s => s === maybeChainNamespace);
exports.isChainNamespace = isChainNamespace;
const isChainReference = (maybeChainReference) => Object.values(constants_1.CHAIN_REFERENCE).some(s => s === maybeChainReference);
exports.isChainReference = isChainReference;
const isAssetNamespace = (maybeAssetNamespace) => Object.values(constants_1.ASSET_NAMESPACE).some(s => s === maybeAssetNamespace);
exports.isAssetNamespace = isAssetNamespace;
const isAssetReference = (maybeAssetReference) => Object.values(constants_1.ASSET_REFERENCE).some(s => s === maybeAssetReference);
exports.isAssetReference = isAssetReference;
const isAssetId = (maybeAssetId) => {
    const matches = utils_1.parseAssetIdRegExp.exec(maybeAssetId);
    if (!matches) {
        return false;
    }
    const { 1: chainNamespace, 2: chainReference, 3: assetNamespace } = matches;
    return ((0, exports.isChainNamespace)(chainNamespace) &&
        (0, exports.isChainReference)(chainReference) &&
        (0, exports.isAssetNamespace)(assetNamespace));
};
exports.isAssetId = isAssetId;
const isChainId = (maybeChainId) => {
    // https://regex101.com/r/iCqlyB/1
    const chainIdRegExp = /(?<chainNamespace>[-a-z\d]{3,8}):(?<chainReference>[-a-zA-Z\d]{1,32})/;
    const [maybeChainNamespace, maybeChainReference] = chainIdRegExp.exec(maybeChainId)?.slice(1) ?? [];
    return ((0, exports.isChainNamespace)(maybeChainNamespace) &&
        (0, exports.isChainReference)(maybeChainReference) &&
        (0, utils_1.isValidChainPartsPair)(maybeChainNamespace, maybeChainReference));
};
exports.isChainId = isChainId;
const getTypeGuardAssertion = (typeGuard, message) => {
    return (value) => {
        if ((value && !typeGuard(value)) || !value)
            throw new Error(`${message}: ${value}`);
    };
};
exports.assertIsChainId = getTypeGuardAssertion(exports.isChainId, 'assertIsChainId: unsupported ChainId');
exports.assertIsChainNamespace = getTypeGuardAssertion(exports.isChainNamespace, 'assertIsChainNamespace: unsupported ChainNamespace');
exports.assertIsChainReference = getTypeGuardAssertion(exports.isChainReference, 'assertIsChainReference: unsupported ChainReference');
exports.assertIsAssetNamespace = getTypeGuardAssertion(exports.isAssetNamespace, 'assertIsAssetNamespace: unsupported AssetNamespace');
exports.assertIsAssetReference = getTypeGuardAssertion(exports.isAssetReference, 'assertIsAssetReference: unsupported AssetReference');
const assertValidChainPartsPair = (chainNamespace, chainReference) => {
    if (!(0, utils_1.isValidChainPartsPair)(chainNamespace, chainReference))
        throw new Error(`toAssetId: Chain Reference ${chainReference} not supported for Chain Namespace ${chainNamespace}`);
};
exports.assertValidChainPartsPair = assertValidChainPartsPair;
