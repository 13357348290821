"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Osmosis Unchained API
 * Provides access to osmosis chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxHistoryToJSON = exports.TxHistoryFromJSONTyped = exports.TxHistoryFromJSON = exports.instanceOfTxHistory = void 0;
const Tx_1 = require("./Tx");
/**
 * Check if a given object implements the TxHistory interface.
 */
function instanceOfTxHistory(value) {
    let isInstance = true;
    isInstance = isInstance && "cursor" in value;
    isInstance = isInstance && "pubkey" in value;
    isInstance = isInstance && "txs" in value;
    return isInstance;
}
exports.instanceOfTxHistory = instanceOfTxHistory;
function TxHistoryFromJSON(json) {
    return TxHistoryFromJSONTyped(json, false);
}
exports.TxHistoryFromJSON = TxHistoryFromJSON;
function TxHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cursor': json['cursor'],
        'pubkey': json['pubkey'],
        'txs': (json['txs'].map(Tx_1.TxFromJSON)),
    };
}
exports.TxHistoryFromJSONTyped = TxHistoryFromJSONTyped;
function TxHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cursor': value.cursor,
        'pubkey': value.pubkey,
        'txs': (value.txs.map(Tx_1.TxToJSON)),
    };
}
exports.TxHistoryToJSON = TxHistoryToJSON;
