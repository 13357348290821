"use strict";
// https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromCAIP2 = exports.toCAIP2 = exports.fromChainId = exports.toChainId = void 0;
const typeGuards_1 = require("../typeGuards");
const toChainId = (args) => {
    const { chainNamespace, chainReference } = args;
    const maybeChainId = `${chainNamespace}:${chainReference}`;
    (0, typeGuards_1.assertIsChainId)(maybeChainId);
    return maybeChainId;
};
exports.toChainId = toChainId;
const fromChainId = chainId => {
    const [chainNamespace, chainReference] = chainId.split(':');
    (0, typeGuards_1.assertIsChainNamespace)(chainNamespace);
    (0, typeGuards_1.assertIsChainReference)(chainReference);
    (0, typeGuards_1.assertValidChainPartsPair)(chainNamespace, chainReference);
    return { chainNamespace, chainReference };
};
exports.fromChainId = fromChainId;
exports.toCAIP2 = exports.toChainId;
exports.fromCAIP2 = exports.fromChainId;
