"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/dogecoin-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTxVoutInnerToJSON = exports.RawTxVoutInnerFromJSONTyped = exports.RawTxVoutInnerFromJSON = exports.instanceOfRawTxVoutInner = void 0;
const runtime_1 = require("../runtime");
const RawTxVoutInnerScriptPubKey_1 = require("./RawTxVoutInnerScriptPubKey");
const RawTxVoutInnerValue_1 = require("./RawTxVoutInnerValue");
/**
 * Check if a given object implements the RawTxVoutInner interface.
 */
function instanceOfRawTxVoutInner(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfRawTxVoutInner = instanceOfRawTxVoutInner;
function RawTxVoutInnerFromJSON(json) {
    return RawTxVoutInnerFromJSONTyped(json, false);
}
exports.RawTxVoutInnerFromJSON = RawTxVoutInnerFromJSON;
function RawTxVoutInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'scriptPubKey': !(0, runtime_1.exists)(json, 'scriptPubKey') ? undefined : (0, RawTxVoutInnerScriptPubKey_1.RawTxVoutInnerScriptPubKeyFromJSON)(json['scriptPubKey']),
        'n': !(0, runtime_1.exists)(json, 'n') ? undefined : json['n'],
        'value': !(0, runtime_1.exists)(json, 'value') ? undefined : (0, RawTxVoutInnerValue_1.RawTxVoutInnerValueFromJSON)(json['value']),
    };
}
exports.RawTxVoutInnerFromJSONTyped = RawTxVoutInnerFromJSONTyped;
function RawTxVoutInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'scriptPubKey': (0, RawTxVoutInnerScriptPubKey_1.RawTxVoutInnerScriptPubKeyToJSON)(value.scriptPubKey),
        'n': value.n,
        'value': (0, RawTxVoutInnerValue_1.RawTxVoutInnerValueToJSON)(value.value),
    };
}
exports.RawTxVoutInnerToJSON = RawTxVoutInnerToJSON;
