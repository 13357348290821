"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.V1Api = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class V1Api extends runtime.BaseAPI {
    /**
     * Get the estimated gas cost for a transaction.
     */
    async estimateGasRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/gas/estimate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.EstimateGasRequestToJSON)(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Get the estimated gas cost for a transaction.
     */
    async estimateGas(requestParameters = {}, initOverrides) {
        const response = await this.estimateGasRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get account details.
     */
    async getAccountRaw(requestParameters, initOverrides) {
        if (requestParameters.pubkey === null || requestParameters.pubkey === undefined) {
            throw new runtime.RequiredError('pubkey', 'Required parameter requestParameters.pubkey was null or undefined when calling getAccount.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/v1/account/{pubkey}`.replace(`{${"pubkey"}}`, encodeURIComponent(String(requestParameters.pubkey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AccountFromJSON)(jsonValue));
    }
    /**
     * Get account details.
     */
    async getAccount(requestParameters, initOverrides) {
        const response = await this.getAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get information about the running coinstack.
     */
    async getInfoRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/v1/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.InfoFromJSON)(jsonValue));
    }
    /**
     * Get information about the running coinstack.
     */
    async getInfo(initOverrides) {
        const response = await this.getInfoRaw(initOverrides);
        return await response.value();
    }
    /**
     * # Get transaction details
     */
    async getTxRaw(requestParameters, initOverrides) {
        if (requestParameters.txid === null || requestParameters.txid === undefined) {
            throw new runtime.RequiredError('txid', 'Required parameter requestParameters.txid was null or undefined when calling getTx.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/v1/tx/{txid}`.replace(`{${"txid"}}`, encodeURIComponent(String(requestParameters.txid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.TxFromJSON)(jsonValue));
    }
    /**
     * # Get transaction details
     */
    async getTx(requestParameters, initOverrides) {
        const response = await this.getTxRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get paginated transaction history.
     */
    async getTxHistoryRaw(requestParameters, initOverrides) {
        if (requestParameters.pubkey === null || requestParameters.pubkey === undefined) {
            throw new runtime.RequiredError('pubkey', 'Required parameter requestParameters.pubkey was null or undefined when calling getTxHistory.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/v1/account/{pubkey}/txs`.replace(`{${"pubkey"}}`, encodeURIComponent(String(requestParameters.pubkey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.TxHistoryFromJSON)(jsonValue));
    }
    /**
     * Get paginated transaction history.
     */
    async getTxHistory(requestParameters, initOverrides) {
        const response = await this.getTxHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get a specific validator.
     */
    async getValidatorRaw(requestParameters, initOverrides) {
        if (requestParameters.pubkey === null || requestParameters.pubkey === undefined) {
            throw new runtime.RequiredError('pubkey', 'Required parameter requestParameters.pubkey was null or undefined when calling getValidator.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/v1/validators/{pubkey}`.replace(`{${"pubkey"}}`, encodeURIComponent(String(requestParameters.pubkey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ValidatorFromJSON)(jsonValue));
    }
    /**
     * Get a specific validator.
     */
    async getValidator(requestParameters, initOverrides) {
        const response = await this.getValidatorRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get the list of current validators.
     */
    async getValidatorsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/v1/validators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ValidatorsFromJSON)(jsonValue));
    }
    /**
     * Get the list of current validators.
     */
    async getValidators(requestParameters = {}, initOverrides) {
        const response = await this.getValidatorsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sends raw transaction to be broadcast to the node.
     */
    async sendTxRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.EstimateGasRequestToJSON)(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Sends raw transaction to be broadcast to the node.
     */
    async sendTx(requestParameters = {}, initOverrides) {
        const response = await this.sendTxRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get paginated transaction history for a validator.
     */
    async validatorTxHistoryRaw(requestParameters, initOverrides) {
        if (requestParameters.pubkey === null || requestParameters.pubkey === undefined) {
            throw new runtime.RequiredError('pubkey', 'Required parameter requestParameters.pubkey was null or undefined when calling validatorTxHistory.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/v1/validators/{pubkey}/txs`.replace(`{${"pubkey"}}`, encodeURIComponent(String(requestParameters.pubkey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.TxHistoryFromJSON)(jsonValue));
    }
    /**
     * Get paginated transaction history for a validator.
     */
    async validatorTxHistory(requestParameters, initOverrides) {
        const response = await this.validatorTxHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.V1Api = V1Api;
