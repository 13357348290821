"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionParser = void 0;
const types_1 = require("../../../types");
const parser_1 = require("../../parser");
class TransactionParser extends parser_1.BaseTransactionParser {
    async parse(tx, address) {
        const parsedTx = await super.parse(tx, address);
        if (parsedTx.data?.method === 'swap_exact_amount_in') {
            parsedTx.trade = {
                dexName: types_1.Dex.Osmosis,
                type: types_1.TradeType.Trade,
            };
        }
        return parsedTx;
    }
}
exports.TransactionParser = TransactionParser;
