"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bitcoincash-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTxVinInnerToJSON = exports.RawTxVinInnerFromJSONTyped = exports.RawTxVinInnerFromJSON = exports.instanceOfRawTxVinInner = void 0;
const runtime_1 = require("../runtime");
const RawTxVinInnerScriptSig_1 = require("./RawTxVinInnerScriptSig");
/**
 * Check if a given object implements the RawTxVinInner interface.
 */
function instanceOfRawTxVinInner(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfRawTxVinInner = instanceOfRawTxVinInner;
function RawTxVinInnerFromJSON(json) {
    return RawTxVinInnerFromJSONTyped(json, false);
}
exports.RawTxVinInnerFromJSON = RawTxVinInnerFromJSON;
function RawTxVinInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'txinwitness': !(0, runtime_1.exists)(json, 'txinwitness') ? undefined : json['txinwitness'],
        'scriptSig': !(0, runtime_1.exists)(json, 'scriptSig') ? undefined : (0, RawTxVinInnerScriptSig_1.RawTxVinInnerScriptSigFromJSON)(json['scriptSig']),
        'coinbase': !(0, runtime_1.exists)(json, 'coinbase') ? undefined : json['coinbase'],
        'sequence': !(0, runtime_1.exists)(json, 'sequence') ? undefined : json['sequence'],
        'vout': !(0, runtime_1.exists)(json, 'vout') ? undefined : json['vout'],
        'txid': !(0, runtime_1.exists)(json, 'txid') ? undefined : json['txid'],
    };
}
exports.RawTxVinInnerFromJSONTyped = RawTxVinInnerFromJSONTyped;
function RawTxVinInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'txinwitness': value.txinwitness,
        'scriptSig': (0, RawTxVinInnerScriptSig_1.RawTxVinInnerScriptSigToJSON)(value.scriptSig),
        'coinbase': value.coinbase,
        'sequence': value.sequence,
        'vout': value.vout,
        'txid': value.txid,
    };
}
exports.RawTxVinInnerToJSON = RawTxVinInnerToJSON;
