"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/dogecoin-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UtxoToJSON = exports.UtxoFromJSONTyped = exports.UtxoFromJSON = exports.instanceOfUtxo = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the Utxo interface.
 */
function instanceOfUtxo(value) {
    let isInstance = true;
    isInstance = isInstance && "txid" in value;
    isInstance = isInstance && "vout" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "confirmations" in value;
    return isInstance;
}
exports.instanceOfUtxo = instanceOfUtxo;
function UtxoFromJSON(json) {
    return UtxoFromJSONTyped(json, false);
}
exports.UtxoFromJSON = UtxoFromJSON;
function UtxoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'txid': json['txid'],
        'vout': json['vout'],
        'value': json['value'],
        'height': !(0, runtime_1.exists)(json, 'height') ? undefined : json['height'],
        'confirmations': json['confirmations'],
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : json['address'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
        'locktime': !(0, runtime_1.exists)(json, 'locktime') ? undefined : json['locktime'],
        'coinbase': !(0, runtime_1.exists)(json, 'coinbase') ? undefined : json['coinbase'],
    };
}
exports.UtxoFromJSONTyped = UtxoFromJSONTyped;
function UtxoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'txid': value.txid,
        'vout': value.vout,
        'value': value.value,
        'height': value.height,
        'confirmations': value.confirmations,
        'address': value.address,
        'path': value.path,
        'locktime': value.locktime,
        'coinbase': value.coinbase,
    };
}
exports.UtxoToJSON = UtxoToJSON;
