"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/dogecoin-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTxVoutInnerValueToJSON = exports.RawTxVoutInnerValueFromJSONTyped = exports.RawTxVoutInnerValueFromJSON = exports.instanceOfRawTxVoutInnerValue = void 0;
/**
 * Check if a given object implements the RawTxVoutInnerValue interface.
 */
function instanceOfRawTxVoutInnerValue(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfRawTxVoutInnerValue = instanceOfRawTxVoutInnerValue;
function RawTxVoutInnerValueFromJSON(json) {
    return RawTxVoutInnerValueFromJSONTyped(json, false);
}
exports.RawTxVoutInnerValueFromJSON = RawTxVoutInnerValueFromJSON;
function RawTxVoutInnerValueFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RawTxVoutInnerValueFromJSONTyped = RawTxVoutInnerValueFromJSONTyped;
function RawTxVoutInnerValueToJSON(value) {
    return value;
}
exports.RawTxVoutInnerValueToJSON = RawTxVoutInnerValueToJSON;
