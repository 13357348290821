"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Osmosis Unchained API
 * Provides access to osmosis chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CosmosSDKAccountToJSON = exports.CosmosSDKAccountFromJSONTyped = exports.CosmosSDKAccountFromJSON = exports.instanceOfCosmosSDKAccount = void 0;
const Value_1 = require("./Value");
/**
 * Check if a given object implements the CosmosSDKAccount interface.
 */
function instanceOfCosmosSDKAccount(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "pubkey" in value;
    isInstance = isInstance && "unconfirmedBalance" in value;
    isInstance = isInstance && "accountNumber" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "sequence" in value;
    return isInstance;
}
exports.instanceOfCosmosSDKAccount = instanceOfCosmosSDKAccount;
function CosmosSDKAccountFromJSON(json) {
    return CosmosSDKAccountFromJSONTyped(json, false);
}
exports.CosmosSDKAccountFromJSON = CosmosSDKAccountFromJSON;
function CosmosSDKAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': json['balance'],
        'pubkey': json['pubkey'],
        'unconfirmedBalance': json['unconfirmedBalance'],
        'accountNumber': json['accountNumber'],
        'assets': (json['assets'].map(Value_1.ValueFromJSON)),
        'sequence': json['sequence'],
    };
}
exports.CosmosSDKAccountFromJSONTyped = CosmosSDKAccountFromJSONTyped;
function CosmosSDKAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': value.balance,
        'pubkey': value.pubkey,
        'unconfirmedBalance': value.unconfirmedBalance,
        'accountNumber': value.accountNumber,
        'assets': (value.assets.map(Value_1.ValueToJSON)),
        'sequence': value.sequence,
    };
}
exports.CosmosSDKAccountToJSON = CosmosSDKAccountToJSON;
